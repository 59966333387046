/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../@lekoarts/gatsby-theme-cara/constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import BlogBackground from '../@lekoarts/gatsby-theme-cara/components/blog-background';
import GenericFooter from '../@lekoarts/gatsby-theme-cara/components/generic-footer';
import { graphql } from 'gatsby';

const ThankYou = ({ data }) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Thank You" />
      <Layout className="Blog-post-page">
        <MenuBar />
        <BlogBackground offset={0} />
        <div
          sx={{ margin: ['150px 0', '200px 0', '300px 0'] }}
          className="Pricing-content text-center"
        >
          <h2>Thank you for your submission!</h2>
          <h3>We should get back to you within 24 hours.</h3>
          <br />
          <br />
          <br />
          <br />
          <p>
            Here is a link to our{' '}
            <a href="https://app.termly.io/document/privacy-policy/156f5d6c-3e6c-49e2-971d-f52fb1da4c62">
              privacy policy
            </a>
          </p>
          <img
            height="1"
            width="1"
            sx={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=1820316&conversionId=2932116&fmt=gif"
          />
        </div>
        <GenericFooter />
      </Layout>
    </ThemeProvider>
  );
};

export default ThankYou;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "Coffee.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
